import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../../components/layout'
import Seo from '../../components/seo'
import PageTitle from '../../components/page_title'
import PageContents from '../../components/page_contents'
import * as style from '../../styles/profile.module.css'

export default function ProfileVincent() {
  return (
    <Layout showFooter={true}>
      <Seo
        title='Vince Linden'
        description='Click here to learn more about Vince Linden of the Linden Law Group.'
      />

      <PageContents>
        <PageTitle>Who We Are</PageTitle>

        <div className={`${style.main} wide_content`}>
          <StaticImage
            className={style.photo}
            src='../../images/jpg/vince.jpg'
            alt='Vincent L. Linden III'
            loading='eager'
            height={400}
          />

          <div className={style.description}>
            <span className={style.name}>Vincent L. Linden III</span>
            <span className={style.role}>Attorney, Partner</span>

            <div className={style.text}>
              There is an obvious reason why Vince’s clients are happy about the
              services he provides, regardless of the outcome of their
              particular legal matter - Vince will get into the arena and fight
              for his clients. If that sounds like a television cliché, it is,
              because the advertising agencies recognize that when you have a
              problem you desperately need to have solved you want to hire a
              lawyer who will relentlessly press forward on your behalf. In
              fact, the citizens of Colorado Springs have voted Vince “Best
              Lawyer” on more than one occasion, and he has the highest client
              review rating a lawyer can achieve. If you want a lawyer who will
              be passionate about defending you, advancing your claims, or
              securing your economic interests, Vince is your guy.
              <br />
              <br />
              Vince graduated from the University of New Mexico in 1990 with a
              degree in Business Administration, with an emphasis in Finance. He
              graduated from the University of Denver Sturm College of Law in
              1993. Since arriving in Colorado Springs in 1994, Vince has
              represented clients in hundreds of trials, hearings, arbitrations,
              mediations and other proceedings in both state and federal courts.
              He is a skilled and successful trial lawyer, negotiator, small
              business advisor, and an undisputed expert in the field of liquor
              licensing.
              <br />
              <br />
              Outside of his law practice, Vince has taught law, coached sports,
              and raised three amazing children.
            </div>

            <Link to='/about/mary' className={style.link}>
              Mary Kominek Linden
            </Link>
          </div>
        </div>
      </PageContents>
    </Layout>
  )
}
